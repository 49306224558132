<template>
  <div class="printInfo">
    <div class="bg"></div>
    <div class="top-img flex between center">
      <img class="pointer" src="@/assets/sign-e.png" @click="toHome()" />
      <div class="flex center">
        <!-- toPre -->
        <!-- ExportSavePdf(htmlTitle, nowTime) -->
        <div
          class="print-btn pointer"
          @click="ExportSavePdf(htmlTitle, nowTime)"
        >
          打印
        </div>
        <span class="pointer" @click="toUrl()">返回</span>
      </div>
    </div>
    <div style="padding-top: 20px;">
    <div class="print-main flex between" id="pdfCentent">
      <div class="print-left">
        <!-- <div class="le-tit1" v-if="info.exam.name">{{ info.exam.name }}</div>
        <div class="le-tit2">准考证</div> -->
        <table border="1">
          <tr class="le-tit1">
            <td colspan="3">{{ info.exam.name }}</td>
          </tr>
          <tr class="le-tit1">
            <td colspan="3">准考证</td>
          </tr>
          <tr>
            <td style="width: 21%">考试类别：</td>
            <td colspan="2">{{ info.exam_type_text }}</td>
          </tr>
          <tr>
            <td>考生姓名：</td>
            <td>{{ info.name }}</td>
            <td rowspan="3" style="padding: 10px 15px; width: 36%">
              <!--info.avatar http://192.168.1.185:8085/gaoxin_signup/public/uploads/jpg/20220507/542157b089bed5adc9532a0b5e6329c2.jpg -->
              <img class="tou" :src="info.avatar" />
              <!-- <img class="tou" src="@/assets/tou.jpg" /> -->
            </td>
          </tr>
          <tr>
            <td>报考岗位：</td>
            <td>{{ info.position_name }} {{info.position_code}}</td>
          </tr>
          <tr>
            <td>准考证号：</td>
            <td>{{ info.examinee_number }}</td>
          </tr>
          <tr>
            <td>考试时间：</td>
            <td colspan="2">{{ info.exam_time_desc }}</td>
          </tr>
          <tr style="height: 60px;">
            <td>考试地点：</td>
            <td colspan="2">{{ info.exam_address }}</td>
          </tr>
          <tr>
            <td>考试科目：</td>
            <td colspan="2">{{ info.exam_subject }}</td>
          </tr>
          <tr>
            <td>身份证号：</td>
            <td colspan="2">{{ info.id_number }}</td>
          </tr>
          <tr>
            <td>{{ info.other_name }}：</td>
            <td colspan="2">{{ info.other_content }}</td>
          </tr>
          <tr>
            <td
              colspan="3"
              style="padding: 15px; font-size: 16px; text-align: left"
            >
              {{ info.exam_type_remark }}
            </td>
          </tr>
        </table>
      </div>
      <div class="print-right">
        <div class="rig-tit">{{ info.exam_type_title }}</div>
        <div class="rig-con" v-html="info.exam_type_info"></div>
      </div>
      <div class="tips">
        {{ info.exam_type_bottom }}
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { registrationDetail,registrationDoPrint } from "@/api/apiData";
export default {
  data() {
    return {
      htmlTitle: "准考证",
      nowTime: Date.now(),
      info: {
        avatar: "",
        exam: {
          name: "",
          id: "",
        },
        exam_address: "",
        exam_subject: "",
        exam_time_desc: "",
        exam_type_text: "",
        examinee_number: "",
        id: "",
        id_number: "",
        name: "",
        other_content: "",
        other_name: "",
        position_code: "",
        position_name: "",
        remark: "",
        report_address: "",
        room_number: "",
        telphone: "",
        exam_type_title: "",
        exam_type_remark: "",
        exam_type_info: "",
        exam_type_bottom: "",
      },
    };
  },
  mounted() {
    this.$bus.emit("navS", 0);
    let id = this.$route.query.id;
    registrationDetail({ exam_apply_registration_id: id }).then((res) => {
      this.info = res.data;
      this.htmlTitle = res.data.exam_type_text + "准考证";
    });
    registrationDoPrint({ exam_apply_registration_id: id }).then((res) => {});
  },
  methods: {
    toPre(){
      window.print();
    },
    toUrl() {
      this.$router.go(-1);
    },
    toHome() {
      this.$router.push("/index");
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2f5f8;
  z-index: -1;
}
.top-img {
  margin: 0 auto;
  padding: 20px 20%;
  background: white;
  // margin-bottom: 50px;
  img {
    width: 55%;
  }
  div {
    color: #0089e1;
    font-size: 16px;
  }
  .print-btn {
    background: #1baefd;
    color: white;
    width: 80px;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 30px;
  }
}
.print-main {
  background: white;
  margin: 0 auto;
  border: solid 1px black;
  flex-wrap: wrap;
  width: 1300Px;
  font-size: 20px;
  .print-left {
    width: 50%;
    // border-right: solid 1px black;
    text-align: center;
    color: black;

    .le-tit2 {
      font-size: 24px;
      line-height: 2.5;
      font-weight: bold;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-color: black;
      // font-size: 24px;

      td {
        // width: 33.33333%;
        height: 55px;
        // padding: 5px;
      }
      .tou {
        margin: 0 auto;
        display: block;
        width: 100%;
      }
      .le-tit1 {
        font-size: 22px;
        font-weight: bold;
        height: 60px;
        // border-bottom: solid 1px #dbdbdb;
      }
    }
  }
  .print-right {
    width: 48%;
    padding: 10px;
    color: rgb(73, 73, 73);
    .rig-tit {
      font-size:22px;
      font-weight: bold;
      text-align: center;
      line-height: 2.5;
      margin-bottom: 10px;
    }
    // .rig-con {
    //   font-size: 18px;
    //   span{
    //   font-size: 18px !important;
    //   }
    // }
  }
  .tips {
    padding: 10px;
    color: rgb(73, 73, 73);
    font-size: 16px;
  }
}
.pdfCentent {
  display: none;
}
</style>